<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="600px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <div slot="title" class="title">请选择部门</div>

    <div class="content" v-loading="vloading">
      <el-tree
        ref="tree"
        :data="data"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        :expand-on-click-node="false"
        check-strictly
        :check-on-click-node="true"
        :default-checked-keys="defaultChecked"
        :props="{
          children: 'children',
          label: 'deptName',
        }"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <i :class="'iconfont ' + data.icon" style="color: #399ffb; margin-right: 0px"></i>
          {{ data.deptName }}
        </div>
      </el-tree>
    </div>

    <slot name="footer" slot="footer" v-show="!vloading">
      <el-button type="success" @click="determine">确定</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </slot>
  </el-dialog>
</template>
<script>
import { initTreeData } from '@/util/common'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    defaultChecked: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      vloading: true,
      data: [],
    }
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
  },
  created() {
    // Promise.all([this.$api.sysDept.listDept(), this.$api.sysConfig.listPost()])
    //   .then((r) => {
    //     var arr = [];
    //     var parents = [];

    //     r[0].data.some((res) => {
    //       arr.push({
    //         id: res.id,
    //         label: res.deptName,
    //         parentId: res.parentId,
    //         icon: "iconcompany",
    //         type: "dept",
    //       });
    //     });

    //     this.data = initTreeData(arr);
    //     this.vloading = false;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.vloading = false;
    //   });
    this.$api.sysDept
      .getTreeDept()
      .then(res => {
        if (res.data) {
          function childrenDeleteFn(arr) {
            arr.forEach(v => {
              v.icon = 'iconcompany'
              v.type = 'dept'

              if ((v.children && v.children.length === 0) || v.level === 3) {
                delete v.children
              } else if (v.children && v.children.length > 0) {
                childrenDeleteFn(v.children)
              }
            })
            return arr
          }
          this.data = childrenDeleteFn(res.data)
          this.vloading = false
        }
      })
      .catch(err => {
        console.log(err)
        this.vloading = false
      })
  },
  methods: {
    determine() {
      this.$emit('determine', this.$refs.tree.getCheckedNodes())
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
